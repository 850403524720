import axios from 'axios'

const baseurl = `${process.env.VUE_APP_API_URL}`
const url = `${baseurl}/api/v1`
class StageOneService {
  static createStageOne (data) {
    return axios.post(`${url}/app/stage-one/create`, data)
  }

  static fetchEmployee (id) {
    return axios.get(`${url}/app/stage-one/fetch/${id}`)
  }
}

export default StageOneService
